.questionbankExcelBox {
  display: flex;
  width: 100%;
  height: 10rem;
}
.questionbankExcelBox .questionbank_divBox {
  width: 50%;
  padding: 0.5rem;
  box-sizing: border-box;
}
.questionbankExcelBox .questionbank_divBox .questionbank_div {
  padding: 0.5rem;
  border: 1px solid #f9f9f9;
  height: 100%;
  background: #fafafa;
  display: flex;
}
.questionbankExcelBox .questionbank_divBox .questionbank_div .questionbank_template {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}
.questionbankExcelBox .questionbank_divBox .questionbank_div .questionbank_template .upload-btn {
  height: 32px;
}
.questionbankExcelBox .questionbank_divBox .questionbank_div .questionbank_template .upload-btn .el-upload {
  height: 32px !important;
  border: none !important;
}
.questionbankExcelBox .questionbank_divBox .questionbank_div .questionbank_template .questionbank_button {
  margin-top: 1rem;
}
.FormatBox {
  padding: 1.75rem 1rem;
}
.FormatBox .p_red {
  color: red;
}
.FormatBox .FormatDescriptionBox {
  padding-top: 0.75rem;
  line-height: 1rem;
}
