













.empty-seat {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -100px;
  .empty-img {
      align-items: center;
  }
  img {
    width: 10.9375rem;
  }
}
