.empty-seat {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -100px;
}
.empty-seat .empty-img {
  align-items: center;
}
.empty-seat img {
  width: 10.9375rem;
}
